<template>
  <div>
    <el-form ref="search" :inline="true" class="searchForm" :model="pageParams" style="margin-left: 28px">
      <el-form-item label="关键字" prop="equipmentStatus">
        <el-input
          clearable
          size="mini"
          placeholder="请输入设备名称"
          v-model="pageParams.equipmentDes"
          style="width: 170px"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="" label-width="0">
        <el-button size="mini" type="primary" @click="searching"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px"
          @click="resetForm"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <!--数据表格-->
    <el-table
      border
      :data="tableData"
      height="calc(100vh - 300px)"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign: 'center',
        background: '#f8f8f9',
      }"
      style="margin: 0px 0px 20px 30px; width: 98%"
    >
      <el-table-column type="index" label="序号" align="left" width="50">
      </el-table-column>
      <el-table-column
        prop="taskCode"
        label="任务编号"
        align="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="networkName"
        label="巡检区域"
        align="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="equipmentName"
        label="设备名称"
        align="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="equipmentTypeName"
        label="设备类型"
        align="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="itemName" label="操作" align="center" width="100">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="IsOpenFun(row)"
            >查看</el-button
          >
          <!-- v-if="$anthButtons.getButtonAuth('lookEquipment')" -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
      style="margin-left: 20px"
        :current-page="pageParams.current"
        :background="true"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="设备信息"
      :visible.sync="dialogVisible"
      :show-close="true"
      :close-on-click-modal="false"
      width="460px"
    >
      <el-descriptions :column="1" v-if="Object.keys(chosedRow).length">
        <el-descriptions-item label="设备名称"
          >{{chosedRow.equipmentName}}</el-descriptions-item
        >
        <el-descriptions-item label="设备类型"
          >{{chosedRow.equipmentTypeName}}</el-descriptions-item
        >
        <el-descriptions-item label="规格型号"
          >{{chosedRow.equipmentSpecifications}}</el-descriptions-item
        >
        <el-descriptions-item label="设备状态"
          >{{startName(chosedRow.equipmentStatus)}}</el-descriptions-item
        >
        <el-descriptions-item label="生产日期"
          >{{chosedRow.equipmentTime}}</el-descriptions-item
        >
        <el-descriptions-item label="使用日期"
          >{{chosedRow.equipmentUseTime}}</el-descriptions-item
        >
        <el-descriptions-item label="所在街道"
          >{{chosedRow.equipmentStreet}}</el-descriptions-item
        >
        <el-descriptions-item label="生产厂家"
          >{{chosedRow.equipmentProducers}}</el-descriptions-item
        >
        <el-descriptions-item label="关联管线"
          >{{chosedRow.lineName}}</el-descriptions-item
        >
        <el-descriptions-item label="所在地址"
          >{{chosedRow.equipmentHome}}</el-descriptions-item
        >
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import {serverMap} from "@/views/Gis/utils/dict"
import { pcEquipmentOngoingList, pcEquipmentOngoing2List } from "../../../RequestPort/maintenance/task";
import {dataInfoget} from "@/RequestPort/checkTask/polling";
export default {
  name: "patrolUndone",
  props: ['id', 'taskGenerateStatus'],
  data() {
    return {
      pageParams: {
        equipmentDes: '',
        taskID: this.id,
        current: 1,
        size: 100,
      },
      isUnnormal: [
        { label: "有", id: 1 },
        { label: "无", id: 2 },
      ],
      total: 0,
      times: [],
      dialogVisible: false,
      tableData: [],
      chosedRow: {},
      equipmentStatusList: []
    };
  },
  mounted() {
    // 管线状态
      this.equipmentStatusList = serverMap[1007]
  },
  methods: {
    searching() {
      this.pageParams.current = 1;
      this.loadList();
    },
    resetForm() {
      this.pageParams = {
        equipmentDes: '',
        taskID: this.id,
        current: 1,
        size: 100,
      }
      this.loadList();
    },
    loadList() {
      if (this.taskGenerateStatus == 3) {
        pcEquipmentOngoing2List(this.pageParams).then((res) => {
            this.tableData = res.data.records
            this.total = res.data.total
        });
      } else {
        pcEquipmentOngoingList(this.pageParams).then((res) => {
            this.tableData = res.data.records
            this.total = res.data.total
        });
      }
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.pageParams.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pageParams.current = val;
      this.loadList();
    },
    IsOpenFun(row) {
      this.equipmentinfo(this.id, row)
      this.dialogVisible = true;
    },
    startName(val){
      for(let i=0;i<this.equipmentStatusList.length;i++){
        if(this.equipmentStatusList[i].dictCode == val) {
          return this.equipmentStatusList[i].name
        }
      }
    },
    /**
     * 设备详情
     */
    equipmentinfo(val,row){
      dataInfoget({id:row.equipmentID,tableName:row.relationTable}).then(res => {
        this.chosedRow = res.data[0]
      })
    },
  },
};
</script>
 
<style scoped lang="scss">
</style>