<template>
  <div>
    <div class="content">
      <div class="SearchBar">
        <el-radio-group
          v-model="activeName"
          size="medium"
          style="margin: 20px "
          @change="changeType"
        >
          <el-radio-button label="first">已完成</el-radio-button>
          <el-radio-button label="second">未完成</el-radio-button>
        </el-radio-group>
        <!-- <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button> -->
        <template v-if="activeName == 'first'">
          <el-form
            ref="search"
            class="searchForm"
            :model="paramsData"
            label-width="auto"
            :inline="true"
          >
            <el-form-item label="时间段" prop="equipmentStatus">
              <el-date-picker
                @change="search(1)"
                size="mini"
                style="width: 250px"
                v-model="DataPicker"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="有无异常项"
              prop="isHav"
              style="margin-left: 14px"
            >
              <el-select
                clearable
                size="mini"
                style="width: 170px"
                v-model="paramsData.isHav"
                placeholder="请选择有无异常项"
                @change="search(1)"
              >
                <el-option
                  v-for="item in selectDownArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字" prop="equipmentStatus">
              <el-input
                clearable
                size="mini"
                placeholder="请输入设备名称"
                v-model="paramsData.searchText"
                style="width: 250px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="">
              <el-button
                size="mini"
                type="primary"
                @click="search(1)"
                style="margin-left: 10px"
                >搜索</el-button
              >
              <el-button
                size="mini"
                type="warning"
                style="margin-left: 10px"
                @click="resize"
                >重置</el-button
              >
            </el-form-item>
          </el-form>

          <el-table
            ref="tableData"
            :data="tableData"
            style="margin: 0px 0px 20px 30px; width: 98%"
            border
            height="calc(100vh - 300px)"
            :stripe="true"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign: 'center',
              background: '#f8f8f9',
            }"
          >
            <el-table-column type="index" label="序号" align="left" width="50">
            </el-table-column>
            <el-table-column
              prop="taskCode"
              label="任务编号"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="networkName"
              label="巡检区域"
              align="left"
              show-overflow-tooltip
            >
              <!-- <template slot-scope="{row}">
                  {{ starts(row.inputType) }}
                </template> -->
            </el-table-column>
            <el-table-column
              prop="equipmentName"
              label="设备名称"
              align="left"
              show-overflow-tooltip
            >
              <!-- <template slot-scope="{row}">
                  {{row.requiredFields == 1 ? '是' : '否'}}
                </template> -->
            </el-table-column>
            <el-table-column
              prop="equipmentType"
              label="设备类型"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="isHav"
              label="有无异常项"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span v-if="row.isHav > 0" style="color: #e83434">有</span>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="executorPeopleName"
              label="巡线员"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="巡检时间"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="duration"
              label="巡检时长"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{ parseInt((row.duration / 60 / 60) % 24) }} <span>小时</span>
                {{ parseInt((row.duration / 60) % 60) }} <span>分钟</span>
                {{ parseInt(row.duration % 60) }} <span>秒</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="关联事件(已处理数/总数)"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  style="color: #1082ff; cursor: pointer"
                  v-if="scope.row.processedSum > 0"
                  @click="appear(scope.row)"
                >
                  {{ scope.row.processed }}/{{ scope.row.processedSum }}
                </div>
                <div v-else>
                  {{ scope.row.processed }}/{{ scope.row.processedSum }}
                </div>
                <!-- {{scope.row.processed}}/{{scope.row.processedSum}} -->
              </template>
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="状态"
              align="left"
              width="80"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div v-if="row.processedSum == '0'">--</div>
                <el-tag
                  v-else-if="row.processedSum <= row.processed"
                  type="success"
                  size="mini"
                >
                  已处理
                </el-tag>
                <el-tag
                  v-else
                  size="mini"
                  style="
                    color: #e83434;
                    border: 1px solid #f5a7a7;
                    background: #fff4eb;
                  "
                >
                  待处理
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="操作"
              align="center"
              width="120"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="IsOpenFun(row)"
                  >查看</el-button
                >
                <!-- <el-button
                      type="info"
                      size="mini"
                      
                      @click="toPatrolOrder(row)"
                      >设备巡检工单</el-button
                    > -->
              </template>
            </el-table-column>
            <!-- <el-table-column prop="itemName" label="操作" align="left" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="IsOpenFun(4, row)"
                        >查看</el-button>
                    
                    </template>
              </el-table-column> -->
          </el-table>
          <el-pagination
            style="margin-left: 20px"
            background
            layout="total, prev, pager, next,sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="paramsData.current"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="paramsData.size"
          >
          </el-pagination>
        </template>
        <patrolUndone v-if="activeName == 'second'" ref="patrolUndone" :taskGenerateStatus="taskGenerateStatus " :id="id"></patrolUndone>
      </div>
    </div>
  </div>
</template>
<script>
import { dataPage } from "../../../RequestPort/maintenance/task";
import patrolUndone from "../../lineCheckDicts/options/patrolUndone.vue";

export default {
  data() {
    return {
      paramsData: {
        current: 1,
        size: 100,
      },
      DataPicker: [],
      selectDownArray: [
        { id: 1, name: "有" },
        { id: 2, name: "无" },
      ],
      form: {},
      tableData: [], //  表格数据
      id: "",
      screcd: "",
      total: 0,
      activeName: "first",
      taskGenerateStatus: null
    };
  },
  components: {
    patrolUndone,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.screcd = this.$route.query.screcd;
    this.taskGenerateStatus = this.$route.query.taskGenerateStatus
    this.particulars();
  },
  methods: {
    changeType(e) {
      if (e === 'first') {
        this.search(1)
      } else {
        this.$refs.patrolUndone.searching()
      }
    },
    /**
     * 查看巡检工单
     */
    // toPatrolOrder(row) {
    //   let chakan = this.$router.push({
    //     path: '/home/patrolOrder',      //跳转目标窗口的地址
    //     query:{ }
    //   })
    // },
    IsOpenFun(row) {
      const chakan = this.$router.resolve({
        path: "/home/devicePollingDesc",
        query: { id: row.tplId, row: JSON.stringify(row) },
      });
      window.open(chakan.href)
    },
    /**
     * 上报事件
     */
    appear(row) {
      // row:JSON.stringify(row)
      const chakan = this.$router.resolve({ path: "/home/incident", query: { id: row.eventId } });
      window.open(chakan.href);
    },
    /**
     * 搜索
     */
    search(val) {
      if (val) {
        this.paramsData.current = 1;
      }
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.paramsData.startDate = this.DataPicker[0];
        this.paramsData.endDate = this.DataPicker[1];
      } else {
        this.paramsData.startDate = "";
        this.paramsData.endDate = "";
      }
      this.particulars();
    },
    // 重置
    resize() {
      this.paramsData = {
        current: 1,
        size: 100,
      };
      this.DataPicker = [];
      this.particulars();
    },
    //返回
    closeadd() {
      this.$router.push({
        path: "/home/task",
        query: { first: 1, screcd: this.screcd },
      });
    },
    particulars() {
      this.paramsData.taskId = this.id;
      dataPage(this.paramsData).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.paramsData.current = 1;
      this.particulars();
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.particulars();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh);
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.backbtn {
  margin: 20px 0px 20px 20px;
  width: 82px;
  height: 36px;
}
.row {
  margin-left: 10px;
}
.row2 {
  margin-left: 10px;
  margin-top: 10px;
}
.titleDiv {
  margin: 26px 0px 0px 30px;
}
</style>
